import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../actions/userActions";
//import { useLocation } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import "./Navbar.css";

function Navbar() {
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate hook

  const isOnFirstScreen = location.pathname === "/";

  const [transparent, setTransparent] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  useEffect(() => {
    const handleScroll = () => {
      const isTransparent = window.scrollY < 50;
      // console.log(
      //   "Scroll Y:",
      //   window.scrollY,
      //   "Is Transparent:",
      //   isTransparent
      // ); // Debugging scroll
      setTransparent(isTransparent);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToMediaContainer = () => {
    if (isOnFirstScreen) {
      // Scroll to 100px above the media container
      const mediaContainer = document.getElementById("media-container");
      if (mediaContainer) {
        const mediaContainerTop =
          mediaContainer.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: isMobile ? mediaContainerTop + 50 : mediaContainerTop - 200, // Adjusting scroll position to 100px above
          behavior: "smooth",
        });
      }
    } else {
      // Navigate to the first screen and scroll after navigation
      navigate("/", { state: { scrollTo: "media-container" } });
    }
  };

  const scrollToFooterInfo = () => {
    if (isOnFirstScreen) {
      const footerInfo = document.getElementById("footer-info");
      if (footerInfo) {
        const footerInfoTop =
          footerInfo.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: footerInfoTop - 100, // Adjust to scroll above FooterInfo if needed
          behavior: "smooth",
        });
      }
    } else {
      navigate("/", { state: { scrollTo: "footer-info" } });
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/" &&
      location.state?.scrollTo === "media-container"
    ) {
      const mediaContainer = document.getElementById("media-container");
      if (mediaContainer) {
        const mediaContainerTop =
          mediaContainer.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: mediaContainerTop - 100, // Adjusting scroll position to 100px above
          behavior: "smooth",
        });
      }
    }

    if (
      location.pathname === "/" &&
      location.state?.scrollTo === "footer-info"
    ) {
      const footerInfo = document.getElementById("footer-info");
      if (footerInfo) {
        const footerInfoTop =
          footerInfo.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: footerInfoTop - 100,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg p-3 mb-5 ${
          transparent ? "bg-transparent" : "bg-white rounded"
        }`}
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1000, // Ensure the navbar is always on top
          transition: "background-color 0.3s",
          backgroundColor: transparent ? "transparent" : "#fff",
        }}
      >
        <div className="d-flex align-items-center">
          <a className="navbar-brand" href="/">
            <img
              src="gyankosha_logo.png"
              width="100"
              height="100"
              alt="Gyankosha Logo"
            />
          </a>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {currentUser && !isOnFirstScreen ? (
              <div className="dropdown">
                <a
                  className="dropdown-toggle nav-link btn btn-primary"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    ...buttonShadowStyle,
                    fontFamily: "'Georgia Ref', Georgia, serif",
                    //fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    marginRight: isMobile ? "" : "50px",
                  }}
                >
                  {currentUser.name}
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                      href="/udbodh"
                    >
                      Udbodh
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                      href="/bauddhadakshata"
                    >
                      Bauddha Dakshata
                    </a>
                  </li>
                  {/* <li>
                    <a className="dropdown-item" href="/olympiad">
                      Olympiad
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      className="dropdown-item"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                      href="/getinspired"
                    >
                      Inspiration Stories
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                      href="/paymentplans"
                    >
                      Plans for Premium Quiz
                    </a>
                  </li>
                  {currentUser.paid ? (
                    <li>
                      <a
                        className="dropdown-item"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                        href="/dashboard"
                      >
                        Dashboard
                      </a>
                    </li>
                  ) : (
                    <></>
                  )}
                  {currentUser.isAdmin ||
                  currentUser.isSubAdmin ||
                  currentUser.isSubAdminTwo ? (
                    <li>
                      <a
                        className="dropdown-item"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                        href="/adminpanel"
                      >
                        Admin Panel
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        dispatch(logoutUser(currentUser._id));
                        // Optionally redirect to homepage or login page here after logout
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            ) : isOnFirstScreen ? (
              <>
                {/* <button
                  className="nav-link btn btn-primary"
                  //href=""
                  //role="button"
                  //data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={scrollToMediaContainer}
                  style={{
                    ...buttonShadowStyle,
                    fontFamily: "'Georgia Ref', Georgia, serif",
                    //fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    marginRight: isMobile ? "" : "100px",

                    // paddingLeft: isMobile ? "20px" : "100px",
                    // color: "red",
                  }}
                >
                  About Us
                </button>
                <button
                  className="nav-link btn btn-primary"
                  //href="/"
                  //role="button"
                  //data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={scrollToFooterInfo}
                  style={{
                    ...buttonShadowStyle,
                    fontFamily: "'Georgia Ref', Georgia, serif",
                    //fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    marginRight: isMobile ? "" : "100px",

                    // paddingLeft: isMobile ? "20px" : "100px",
                    // color: "red",
                  }}
                >
                  Contact Us
                </button> */}
                <div className="dropdown">
                  <a
                    className="dropdown-toggle nav-link btn btn-primary"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      ...buttonShadowStyle,
                      fontFamily: "'Georgia Ref', Georgia, serif",
                      //fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center",
                      marginRight: isMobile ? "" : "100px",

                      // paddingLeft: isMobile ? "20px" : "100px",
                      // color: "red",
                    }}
                  >
                    E x p l o r e
                  </a>

                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/middlescreen2"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Udbodh
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/middlescreen"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Bauddha Dakshata
                      </a>
                    </li>
                    {/* <li>
                    <a className="dropdown-item" href="/olympiad">
                      Olympiad
                    </a>
                  </li> */}
                    {/* <li>
                    <a
                      className="dropdown-item"
                      href="/getinspired"
                      style={{
                        fontFamily: '"Libre Baskerville", serif',
                        fontWeight: "bold",
                      }}
                    >
                      Ispiration Stories
                    </a>
                  </li> */}
                    <li>
                      <a
                        className="dropdown-item"
                        href="/paymentplans"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Plans for Premium Quiz
                      </a>
                    </li>
                    <li>
                      {currentUser ? (
                        <a
                          className="dropdown-item"
                          href="#"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            dispatch(logoutUser(currentUser._id));
                            // Optionally redirect to homepage or login page here after logout
                          }}
                        >
                          Logout
                        </a>
                      ) : (
                        <a
                          className="dropdown-item"
                          href="/login"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                        >
                          Login
                        </a>
                      )}
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/register"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Register
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/tutorial"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Tutorial for Registration
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/login"
                  style={{
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                >
                  Login
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>
      {/* Padding to offset the fixed navbar height */}
      <div style={{ paddingTop: "70px" }}></div>
    </div>
  );
}

export default Navbar;
