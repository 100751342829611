import React,{useState,useEffect} from "react";

export default function Tutorial() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div style={{marginTop:isMobile?"100px":"100px"}}>
        <iframe
          width={isMobile?"350":"853"}
          height={isMobile?"200":"480"}npm 
          src={"https://www.youtube.com/embed/dx9U8oyTPo4"}
          frameBorder="50"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Tutorial Video"
        />
      </div>
    </div>
  );
}
