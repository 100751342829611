import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllSubjects } from "../actions/subjectActions";
import { getAllDifficultys } from "../actions/difficultyActions";
import { getAllClassss } from "../actions/classsActions";
import Compressor from "compressorjs";

import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap

const AddQuiz = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [classs, setClasss] = useState("");
  const [division, setDivision] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [progress, setProgress] = useState(0); // State to track upload progress
  const [loading, setLoading] = useState(false); // Loader state

  // IndexedDB setup
  const [db, setDb] = useState(null);

  // Inside AddQuiz component
  const [showCanvasModal, setShowCanvasModal] = useState(false); // Modal visibility state
  const [canvasImageSrc, setCanvasImageSrc] = useState(""); // Image source for modal

  const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
  const {
    subjects,
    loading: subjectsLoading,
    error: subjectsError,
  } = subjectsState;

  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const {
    difficultys,
    loading: difficultysLoading,
    error: difficultysError,
  } = difficultysState;

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  useEffect(() => {
    dispatch(getAllSubjects());
    dispatch(getAllDifficultys());
    dispatch(getAllClassss());
  }, [dispatch]);

  useEffect(() => {
    const request = indexedDB.open("quizImagesDB", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("quizImages", { keyPath: "id" });
    };

    request.onsuccess = (event) => {
      setDb(event.target.result);
    };

    request.onerror = (event) => {
      console.error("Error opening IndexedDB:", event.target.error);
    };
  }, []);

  // Function to handle canvas click and show modal with the canvas image
  const handleCanvasClick = (canvasId) => {
    const canvas = document.getElementById(canvasId);
    if (canvas) {
      const imageSrc = canvas.toDataURL(); // Get the image data URL from the canvas
      setCanvasImageSrc(imageSrc); // Set the image source for the modal
      setShowCanvasModal(true); // Show the modal
    }
  };

  // Function to handle saving images to IndexedDB
  const saveImageToIndexedDB = (canvasId, imageKey, imageDataUrl = null) => {
    let imageData;

    if (imageDataUrl) {
      imageData = imageDataUrl; // Use provided image data (from shifting images)
    } else {
      const canvas = document.getElementById(canvasId);
      imageData = canvas.toDataURL(); // Save current canvas data
    }

    const transaction = db.transaction("quizImages", "readwrite");
    const objectStore = transaction.objectStore("quizImages");
    const imageRecord = { id: imageKey, imageData };

    objectStore.put(imageRecord);

    transaction.oncomplete = () => {
      alert(`Image saved: ${imageKey}`);
    };

    transaction.onerror = (event) => {
      console.error("Error saving image:", event.target.error);
    };
  };

  // Function to handle restoring images from IndexedDB
  const restoreImageFromIndexedDB = (canvasId, imageKey) => {
    const transaction = db.transaction("quizImages", "readonly");
    const objectStore = transaction.objectStore("quizImages");

    const getRequest = objectStore.get(imageKey);

    getRequest.onsuccess = (event) => {
      const result = event.target.result;
      if (result) {
        const img = new Image();
        img.onload = function () {
          // Get the original image dimensions
          const originalWidth = img.width;
          const originalHeight = img.height;

          // Set the maximum width and height for display (e.g., 500x500)
          const maxDisplayWidth = 500;
          const maxDisplayHeight = 500;

          // Calculate the scaling factor to maintain aspect ratio
          let scaleFactor = Math.min(
            maxDisplayWidth / originalWidth,
            maxDisplayHeight / originalHeight
          );

          // Calculate the display width and height
          const displayWidth = originalWidth * scaleFactor;
          const displayHeight = originalHeight * scaleFactor;

          // Get the canvas element
          const canvas = document.getElementById(canvasId);
          const ctx = canvas.getContext("2d");

          // Set the canvas size to the original image size for storage purposes
          canvas.width = originalWidth;
          canvas.height = originalHeight;

          // Clear the canvas and draw the image at the original size (for storage)
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

          // Now adjust the canvas element display style for fitting within the screen
          canvas.style.width = `${displayWidth}px`;
          canvas.style.height = `${displayHeight}px`;
        };
        img.src = result.imageData; // Load the image from the IndexedDB data
      } else {
        alert(`No image found for ${imageKey}`);
      }
    };

    getRequest.onerror = (event) => {
      console.error("Error retrieving image:", event.target.error);
    };
  };

  const restoreAllImages = async () => {
    // Restore the quiz image
    await restoreImageFromIndexedDB("quizCanvas", "quizImage");

    // Loop through all the questions and restore their images
    questions.forEach((question, index) => {
      restoreImageFromIndexedDB(
        `questionCanvas${index}`,
        `questionImage${index}`
      );
      restoreImageFromIndexedDB(`answerCanvas${index}`, `answerImage${index}`);
    });
  };

  const handleImageChange = (event, canvasId) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = function () {
          // Get the original image dimensions
          const originalWidth = img.width;
          const originalHeight = img.height;

          // Set the maximum width and height for display (e.g., 500x500)
          const maxDisplayWidth = 500;
          const maxDisplayHeight = 500;

          // Calculate the scaling factor to maintain aspect ratio
          let scaleFactor = Math.min(
            maxDisplayWidth / originalWidth,
            maxDisplayHeight / originalHeight
          );

          // Calculate the display width and height
          const displayWidth = originalWidth * scaleFactor;
          const displayHeight = originalHeight * scaleFactor;

          // Get the canvas element
          const canvas = document.getElementById(canvasId);
          const ctx = canvas.getContext("2d");

          // Set the canvas size to the original image size for storage purposes
          canvas.width = originalWidth;
          canvas.height = originalHeight;

          // Clear the canvas and draw the image at the original size (for storage)
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

          // Now adjust the canvas element display style for fitting within the screen
          canvas.style.width = `${displayWidth}px`;
          canvas.style.height = `${displayHeight}px`;
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: "",
        question_in_hindi: "",
        question_image: null,
        options: ["", "", "", ""],
        correct_answer: "",
        answer_explanation_in_english: "",
        answer_explanation_in_hindi: "",
        answer_image: null,
      },
    ]);
  };

  const removeImageFromIndexedDB = (imageKey) => {
    const transaction = db.transaction("quizImages", "readwrite");
    const objectStore = transaction.objectStore("quizImages");

    const deleteRequest = objectStore.delete(imageKey);

    deleteRequest.onsuccess = () => {
      console.log(`Image with key ${imageKey} deleted from IndexedDB.`);
    };

    deleteRequest.onerror = (event) => {
      console.error(
        `Failed to delete image with key ${imageKey}:`,
        event.target.error
      );
    };
  };

  const getImageFromIndexedDB = (imageKey) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("quizImages", "readonly");
      const objectStore = transaction.objectStore("quizImages");
      const getRequest = objectStore.get(imageKey);

      getRequest.onsuccess = (event) => {
        if (event.target.result) {
          resolve(event.target.result.imageData); // Resolve the image data (base64 or blob)
        } else {
          resolve(null); // Resolve null if no image is found
        }
      };

      getRequest.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  const removeQuestion = async (index) => {
    // Remove the question and answer images of the removed question from IndexedDB
    removeImageFromIndexedDB(`questionImage${index}`);
    removeImageFromIndexedDB(`answerImage${index}`);

    // Shift all subsequent images in IndexedDB
    for (let i = index + 1; i < questions.length; i++) {
      // Get the question and answer images for the next question
      const nextQuestionImage = await getImageFromIndexedDB(
        `questionImage${i}`
      );
      const nextAnswerImage = await getImageFromIndexedDB(`answerImage${i}`);

      // Save the images to the current index position and restore them on canvas
      if (nextQuestionImage) {
        await saveImageToIndexedDB(
          `questionCanvas${i - 1}`,
          `questionImage${i - 1}`,
          nextQuestionImage
        );
        restoreImageFromIndexedDB(
          `questionCanvas${i - 1}`,
          `questionImage${i - 1}`
        );
      }
      if (nextAnswerImage) {
        await saveImageToIndexedDB(
          `answerCanvas${i - 1}`,
          `answerImage${i - 1}`,
          nextAnswerImage
        );
        restoreImageFromIndexedDB(
          `answerCanvas${i - 1}`,
          `answerImage${i - 1}`
        );
      }

      // Remove the old images from IndexedDB
      removeImageFromIndexedDB(`questionImage${i}`);
      removeImageFromIndexedDB(`answerImage${i}`);
    }

    // Finally, remove the question from the questions array
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleFileChange = (index, field, file) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = file;
    setQuestions(updatedQuestions);
  };

  const saveToLocalStorage = () => {
    const data = {
      name,
      difficulty,
      classs,
      division,
      subject,
      questions,
    };
    localStorage.setItem("quizData", JSON.stringify(data));
    alert("Data saved to local storage!");
  };

  const restoreFromLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem("quizData"));
    if (data) {
      setName(data.name);
      setDifficulty(data.difficulty);
      setClasss(data.classs);
      setDivision(data.division);
      setSubject(data.subject);
      setQuestions(data.questions);
      alert("Data restored from local storage!");
    } else {
      alert("No data found in local storage!");
    }
  };

  // Function to prevent form submission on Enter key press
  const preventEnterSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
    }
  };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true); // Show loader
  //   setProgress(0); // Reset progress

  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("difficulty", difficulty);
  //   formData.append("classs", classs);
  //   formData.append("division", division);
  //   formData.append("subject", subject);

  //   // If there is a file for quiz image (from <input type="file">)
  //   if (file) {
  //     formData.append("quiz_image", file);
  //   }

  //   // Append quiz image from canvas if it's not empty
  //   const quizCanvas = document.getElementById("quizCanvas");
  //   const quizBlob = await canvasToBlob(quizCanvas);
  //   if (quizBlob) {
  //     formData.append("quiz_image", quizBlob, "quiz_image.png");
  //   }

  //   // Process all questions and append question and answer images from their canvases
  //   const questionsWithImages = await Promise.all(
  //     questions.map(async (question, index) => {
  //       const questionCanvas = document.getElementById(
  //         `questionCanvas${index}`
  //       );
  //       const answerCanvas = document.getElementById(`answerCanvas${index}`);

  //       let questionBlob = null;
  //       let answerBlob = null;

  //       // Convert question image canvas to blob if it's not empty
  //       if (questionCanvas) {
  //         questionBlob = await canvasToBlob(questionCanvas);
  //         if (questionBlob) {
  //           formData.append(
  //             "questions_images",
  //             questionBlob,
  //             `question_image_${index}.png`
  //           );
  //         }
  //       }

  //       // Convert answer image canvas to blob if it's not empty
  //       if (answerCanvas) {
  //         answerBlob = await canvasToBlob(answerCanvas);
  //         if (answerBlob) {
  //           formData.append(
  //             "questions_images",
  //             answerBlob,
  //             `answer_image_${index}.png`
  //           );
  //         }
  //       }

  //       return {
  //         ...question,
  //         question_image: questionBlob ? `question_image_${index}.png` : null,
  //         answer_image: answerBlob ? `answer_image_${index}.png` : null,
  //       };
  //     })
  //   );

  //   // Append the processed questions (with question_image and answer_image updated)
  //   formData.append("questions", JSON.stringify(questionsWithImages));

  //   // Submit the form data
  //   try {
  //     const response = await axios.post("/api/quizs/addquiz", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       timeout: 600000, // Set timeout to 10 minutes
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         setProgress(percentCompleted - 1); // Update progress bar
  //       },
  //     });

  //     setProgress(100);
  //     setSubmitSuccess(true);
  //     setLoading(false); // Hide loader
  //     setTimeout(() => {
  //       alert("Quiz Submitted Successfully");
  //     }, 500);
  //   } catch (error) {
  //     console.error("Error in quiz submission:", error);
  //     setLoading(false); // Hide loader
  //   }
  // };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setProgress(0); // Reset progress

  //   // Create a formData for the quiz with empty questions
  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("difficulty", difficulty);
  //   formData.append("classs", classs);
  //   formData.append("division", division);
  //   formData.append("subject", subject);

  //   // If there is a file for the quiz image
  //   if (file) {
  //     formData.append("quiz_image", file);
  //   }

  //   // Submit the quiz with empty questions
  //   try {
  //     const response = await axios.post("/api/quizs/addquiz", formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //       timeout: 600000, // Set timeout to 10 minutes
  //     });

  //     const quizId = response.data.quizId;
  //     alert("Quiz with empty questions submitted successfully");

  //     // Submit questions one by one
  //     for (let i = 0; i < questions.length; i++) {
  //       const questionData = new FormData();

  //       // Append the question and its details
  //       questionData.append("quizId", quizId);
  //       questionData.append("question", JSON.stringify(questions[i]));

  //       // Append question image if present
  //       const questionCanvas = document.getElementById(`questionCanvas${i}`);
  //       const questionBlob = await canvasToBlob(questionCanvas);
  //       if (questionBlob) {
  //         questionData.append(
  //           "questions_images",
  //           questionBlob,
  //           `question_image_${i}.png`
  //         );
  //       }

  //       // Append answer image if present
  //       const answerCanvas = document.getElementById(`answerCanvas${i}`);
  //       const answerBlob = await canvasToBlob(answerCanvas);
  //       if (answerBlob) {
  //         questionData.append(
  //           "questions_images",
  //           answerBlob,
  //           `answer_image_${i}.png`
  //         );
  //       }

  //       // Submit the current question
  //       await axios.post("/api/quizs/addquestion", questionData, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //         timeout: 600000,
  //       });

  //       alert(`Question ${i + 1} submitted successfully`);
  //     }

  //     setLoading(false); // Hide loader after all submissions
  //   } catch (error) {
  //     console.error("Error in quiz submission:", error);
  //     setLoading(false); // Hide loader in case of error
  //   }
  // };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setProgress(0); // Reset progress

  //   // Create a formData for the quiz with empty questions
  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("difficulty", difficulty);
  //   formData.append("classs", classs);
  //   formData.append("division", division);
  //   formData.append("subject", subject);

  //   // If there is a file for the quiz image, convert it to Blob
  //   const quizCanvas = document.getElementById("quizCanvas");
  //   const quizBlob = await canvasToBlob(quizCanvas);
  //   if (quizBlob) {
  //     formData.append("quiz_image", quizBlob, "quiz_image.png");
  //   }

  //   // Submit the quiz with empty questions
  //   try {
  //     const response = await axios.post("/api/quizs/addquiz", formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //       timeout: 600000, // Set timeout to 10 minutes
  //     });

  //     const quizId = response.data.quizId;
  //     alert("Quiz with empty questions submitted successfully");

  //     // Submit questions one by one
  //     for (let i = 0; i < questions.length; i++) {
  //       const questionData = new FormData();

  //       // Append the question and its details
  //       questionData.append("quizId", quizId);
  //       questionData.append("question", JSON.stringify(questions[i]));

  //       // Append question image if present
  //       const questionCanvas = document.getElementById(`questionCanvas${i}`);
  //       const questionBlob = await canvasToBlob(questionCanvas);
  //       if (questionBlob) {
  //         questionData.append(
  //           "questions_images",
  //           questionBlob,
  //           `question_image_${i}.png`
  //         );
  //       }

  //       // Append answer image if present
  //       const answerCanvas = document.getElementById(`answerCanvas${i}`);
  //       const answerBlob = await canvasToBlob(answerCanvas);
  //       if (answerBlob) {
  //         questionData.append(
  //           "questions_images",
  //           answerBlob,
  //           `answer_image_${i}.png`
  //         );
  //       }
  //       try {
  //         // Submit the current question
  //         await axios.post("/api/quizs/addquestion", questionData, {
  //           headers: { "Content-Type": "multipart/form-data" },
  //           timeout: 600000,
  //         });

  //         alert(`Question ${i + 1} submitted successfully`);
  //       } catch (error) {
  //         console.error(error);
  //         alert(`Error in Question ${i + 1} submission.`);
  //       }
  //     }

  //     setLoading(false); // Hide loader after all submissions
  //   } catch (error) {
  //     console.error("Error in quiz submission:", error);
  //     setLoading(false); // Hide loader in case of error
  //   }
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setProgress(0); // Reset progress

    const formData = new FormData();
    formData.append("name", name);
    formData.append("difficulty", difficulty);
    formData.append("classs", classs);
    formData.append("division", division);
    formData.append("subject", subject);

    // Compress and convert quiz image to Blob
    const quizCanvas = document.getElementById("quizCanvas");
    const quizBlob = await canvasToBlob(quizCanvas, 0.8); // Compress with 0.8 quality
    if (quizBlob) {
      formData.append("quiz_image", quizBlob, "quiz_image.png");
    }

    // Submit the quiz with empty questions
    try {
      const response = await axios.post("/api/quizs/addquiz", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 600000, // Set timeout to 10 minutes
      });

      const quizId = response.data.quizId;
      alert("Quiz with empty questions submitted successfully");

      // Submit questions one by one
      for (let i = 0; i < questions.length; i++) {
        const questionData = new FormData();

        questionData.append("quizId", quizId);
        questionData.append("question", JSON.stringify(questions[i]));

        // Compress and append question image
        const questionCanvas = document.getElementById(`questionCanvas${i}`);
        const questionBlob = await canvasToBlob(questionCanvas, 0.8); // Ensure < 1MB
        if (questionBlob) {
          questionData.append(
            "questions_images",
            questionBlob,
            `question_image_${i}.png`
          );
        }

        // Compress and append answer image
        const answerCanvas = document.getElementById(`answerCanvas${i}`);
        const answerBlob = await canvasToBlob(answerCanvas, 0.8); // Ensure < 1MB
        if (answerBlob) {
          questionData.append(
            "questions_images",
            answerBlob,
            `answer_image_${i}.png`
          );
        }

        await axios.post("/api/quizs/addquestion", questionData, {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 600000,
        });

        alert(`Question ${i + 1} submitted successfully`);
      }

      setLoading(false); // Hide loader after all submissions
    } catch (error) {
      console.error("Error in quiz submission:", error);
      setLoading(false); // Hide loader in case of error
    }
  };

  // Helper function to convert canvas to Blob
  // const canvasToBlob = (canvas) => {
  //   return new Promise((resolve) => {
  //     const ctx = canvas.getContext("2d");
  //     const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  //     // Check if canvas contains only transparent pixels
  //     let isCanvasEmpty = true;
  //     for (let i = 0; i < canvasData.data.length; i += 4) {
  //       if (canvasData.data[i + 3] !== 0) {
  //         // If any pixel has non-zero alpha value
  //         isCanvasEmpty = false;
  //         break;
  //       }
  //     }

  //     // If the canvas is not empty, convert to Blob; otherwise, resolve null
  //     if (!isCanvasEmpty) {
  //       canvas.toBlob((blob) => {
  //         resolve(blob);
  //       });
  //     } else {
  //       resolve(null); // Resolve null if the canvas is empty
  //     }
  //   });
  // };

  const canvasToBlob = (canvas, quality = 0.8, maxSizeMB = 1) => {
    return new Promise((resolve) => {
      const compressImage = (blob, quality) => {
        // Use Compressor to compress the image
        new Compressor(blob, {
          quality, // Set quality between 0 and 1 (lower quality reduces file size)
          maxWidth: 1000, // Set max width for the compressed image
          maxHeight: 1000, // Set max height for the compressed image
          success(compressedBlob) {
            // Check if the compressed image is within the max size limit
            if (compressedBlob.size / 1024 / 1024 <= maxSizeMB) {
              resolve(compressedBlob); // If size is under 1MB, resolve the promise with compressed image
            } else if (quality > 0.1) {
              // If still larger than 1MB, recursively compress with a lower quality
              compressImage(compressedBlob, quality - 0.1); // Reduce quality further
            } else {
              resolve(compressedBlob); // If quality is too low, resolve with the best we can get
            }
          },
          error(err) {
            console.error("Image compression error:", err);
            resolve(blob); // Fallback to the original Blob if compression fails
          },
        });
      };

      const ctx = canvas.getContext("2d");
      canvas.toBlob((blob) => {
        if (blob && blob.size / 1024 / 1024 > maxSizeMB) {
          // If original image exceeds the size limit, compress it
          compressImage(blob, quality);
        } else {
          resolve(blob); // If it's already under the size limit, return the original Blob
        }
      }, "image/png");
    });
  };

  return (
    <div className="container">
      <hr></hr>
      <h1>Add New Quiz</h1>
      <hr></hr>
      <form
        onSubmit={submitHandler}
        encType="multipart/form-data"
        onKeyDown={preventEnterSubmit}
      >
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter quiz name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ border: "1px solid black", marginBottom: "10px" }}
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
            className="form-control"
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Category</option>
            {difficultys &&
              difficultys.map((diff) => (
                <option key={diff._id} value={diff.diff}>
                  {diff.diff}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={classs}
            onChange={(e) => setClasss(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Class</option>
            {classss &&
              classss.map((cls) => (
                <option key={cls._id} value={cls.cls}>
                  {cls.cls}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Division</label>
          <select
            className="form-control"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Division</option>

            <option value="Udbodh">Udbodh</option>
            <option value="Bauddha Dakshata">Bauddha Dakshata</option>
          </select>
        </div>

        <div className="form-group">
          <label>Subject</label>
          <select
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Subject</option>
            {subjects &&
              subjects.map((sub) => (
                <option key={sub._id} value={sub.sub}>
                  {sub.sub}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group pd-5">
          <label>Upload Quiz Image</label>
          <input
            type="file"
            className="form-control-file"
            name="quiz_image"
            onChange={(e) => handleImageChange(e, "quizCanvas")}
            style={{ marginBottom: "30px" }}
          />
          <canvas
            id="quizCanvas"
            width="300"
            height="300"
            style={{ border: "1px solid black" }}
          ></canvas>
          <br />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveImageToIndexedDB("quizCanvas", "quizImage")}
          >
            Save Image
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => restoreImageFromIndexedDB("quizCanvas", "quizImage")}
          >
            Restore Image
          </button>
        </div>
        {questions.map((question, index) => (
          <div key={index} className="mb-3">
            <label>Question {index + 1}</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${index + 1}`}
              value={question.question || ""}
              onChange={(e) =>
                handleQuestionChange(index, "question", e.target.value)
              }
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${index + 1} in Hindi`}
              value={question.question_in_hindi || ""}
              onChange={(e) =>
                handleQuestionChange(index, "question_in_hindi", e.target.value)
              }
            />
            <label>Options</label>
            {question.options.map((option, optionIndex) => (
              <input
                key={optionIndex}
                type="text"
                className="form-control mb-2"
                placeholder={`Enter option ${optionIndex + 1}`}
                value={option || ""}
                onChange={(e) =>
                  handleOptionChange(index, optionIndex, e.target.value)
                }
              />
            ))}
            <div className="form-group">
              <label>Correct Answer</label>
              <select
                className="form-control"
                value={question.correct_answer || ""}
                onChange={(e) =>
                  handleQuestionChange(index, "correct_answer", e.target.value)
                }
                onBlur={(e) =>
                  handleQuestionChange(index, "correct_answer", e.target.value)
                }
              >
                <option key="" value="">
                  Select Correct Option
                </option>
                {question.options.map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Upload Question Image</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) => handleImageChange(e, `questionCanvas${index}`)}
                style={{ marginBottom: "10px" }}
              />
              <canvas
                id={`questionCanvas${index}`}
                width="300"
                height="300"
                style={{ border: "1px solid black", cursor: "pointer" }}
                onClick={() => handleCanvasClick(`questionCanvas${index}`)} // Trigger modal on click
              ></canvas>
              <br />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  saveImageToIndexedDB(
                    `questionCanvas${index}`,
                    `questionImage${index}`
                  )
                }
              >
                Save Image
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  restoreImageFromIndexedDB(
                    `questionCanvas${index}`,
                    `questionImage${index}`
                  )
                }
              >
                Restore Image
              </button>
            </div>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in English`}
              value={question.answer_explanation_in_english || ""}
              onChange={(e) =>
                handleQuestionChange(
                  index,
                  "answer_explanation_in_english",
                  e.target.value
                )
              }
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in Hindi`}
              value={question.answer_explanation_in_hindi || ""}
              onChange={(e) =>
                handleQuestionChange(
                  index,
                  "answer_explanation_in_hindi",
                  e.target.value
                )
              }
            />
            <div className="form-group">
              <label>Upload Answer Image</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) => handleImageChange(e, `answerCanvas${index}`)}
                style={{ marginBottom: "10px" }}
              />
              <canvas
                id={`answerCanvas${index}`}
                width="300"
                height="300"
                style={{ border: "1px solid black", cursor: "pointer" }}
                onClick={() => handleCanvasClick(`answerCanvas${index}`)} // Trigger modal on click
              ></canvas>
              <br />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  saveImageToIndexedDB(
                    `answerCanvas${index}`,
                    `answerImage${index}`
                  )
                }
              >
                Save Image
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  restoreImageFromIndexedDB(
                    `answerCanvas${index}`,
                    `answerImage${index}`
                  )
                }
              >
                Restore Image
              </button>
            </div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeQuestion(index)}
              style={{ marginTop: "10px" }}
            >
              Remove Question
            </button>
          </div>
        ))}

        {loading && (
          <div className="loader">Submitting...</div> // Loader message
        )}

        {/* Progress Bar */}
        {/*loading && (
          <div>
            <h4>Uploading... {progress}%</h4>
            <progress value={progress} max="100"></progress>
          </div>
        )*/}
        <button
          type="button"
          className="btn btn-primary mb-3 mx-5"
          onClick={addQuestion}
        >
          Add Question
        </button>
        <button type="submit" className="btn btn-primary mb-3 mx-5">
          Submit
        </button>
        <button
          type="button"
          className="btn btn-secondary mb-3 mx-5"
          onClick={saveToLocalStorage}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary mb-3 mx-5"
          onClick={restoreFromLocalStorage}
        >
          Restore Text Fields
        </button>
        <button
          type="button"
          className="btn btn-secondary mb-3 mx-5"
          onClick={restoreAllImages}
        >
          Restore All Images
        </button>
      </form>

      {/* Modal for displaying canvas images */}
      <Modal
        show={showCanvasModal}
        onHide={() => setShowCanvasModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Canvas Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={canvasImageSrc}
            className="img-fluid"
            style={{ maxWidth: "100%", height: "auto" }}
            alt="Canvas Image"
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowCanvasModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddQuiz;
