import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../actions/userActions";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { getAllQuizs, updateQuizEnabledStatus } from "../actions/quizActions"; // Import the quiz actions

const EnableUser = () => {
  const [selectedSchoolcode, setSelectedSchoolcode] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [selectedQuizClass, setSelectedQuizClass] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [filteredQuizNames, setFilteredQuizNames] = useState([]);

  const dispatch = useDispatch();
  const users = useSelector((state) => state.getAllUsersReducer.users);
  const schoolcodes = useSelector(
    (state) => state.getAllSchoolcodesReducer.schoolcodes
  );
  const quizs = useSelector((state) => state.getAllQuizsReducer.quizs);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllSchoolcodes());
    dispatch(getAllQuizs());
  }, [dispatch]);

  // Filter users based on selections
  useEffect(() => {
    let filtered = users;
    if (selectedSchoolcode) {
      filtered = filtered.filter(
        (user) => user.schoolcode === selectedSchoolcode
      );
    }
    if (selectedClass) {
      filtered = filtered.filter((user) => user.classs === selectedClass);
    }
    if (selectedSection) {
      filtered = filtered.filter((user) => user.section === selectedSection);
    }
    setFilteredUsers(filtered);
  }, [selectedSchoolcode, selectedClass, selectedSection, users]);

  // Filter quizzes based on selections
  useEffect(() => {
    let filtered = quizs;
    if (selectedQuizClass) {
      filtered = filtered.filter((quiz) => quiz.classs === selectedQuizClass);
    }
    if (selectedCategory) {
      filtered = filtered.filter(
        (quiz) => quiz.difficulty === selectedCategory
      );
    }
    if (selectedSubject) {
      filtered = filtered.filter((quiz) => quiz.subject === selectedSubject);
    }
    setFilteredQuizNames(filtered);
  }, [selectedQuizClass, selectedCategory, selectedSubject, quizs]);

  // Handlers for dropdown changes
  const handleSchoolcodeChange = (e) => {
    setSelectedSchoolcode(e.target.value);
    setSelectedClass("");
    setSelectedSection("");
    setSelectedName("");
    setSelectedEmail("");
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setSelectedSection("");
    setSelectedName("");
    setSelectedEmail("");
  };

  const handleSectionChange = (e) => {
    setSelectedSection(e.target.value);
    setSelectedName("");
    setSelectedEmail("");
  };

  const handleNameChange = (e) => {
    setSelectedName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  const handleQuizClassChange = (e) => {
    setSelectedQuizClass(e.target.value);
    setSelectedCategory("");
    setSelectedSubject("");
    setSelectedQuiz("");
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubject("");
    setSelectedQuiz("");
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setSelectedQuiz("");
  };

  const handleQuizChange = (e) => {
    setSelectedQuiz(e.target.value);
  };

  // Check if the selected email is already enabled for the selected quiz
  const isEmailEnabled = () => {
    const quiz = quizs.find((quiz) => quiz._id === selectedQuiz);
    return quiz?.enabled.includes(selectedEmail);
  };

  const handleEnable = () => {
    dispatch(updateQuizEnabledStatus(selectedQuiz, selectedEmail, true));
  };

  const handleDisable = () => {
    dispatch(updateQuizEnabledStatus(selectedQuiz, selectedEmail, false));
  };

  return (
    <div>
      <hr></hr>
      <h1>Enable Quiz for User</h1>
      <hr></hr>
      <div className="form-group">
        <label>School Code</label>
        <select
          className="form-control"
          value={selectedSchoolcode}
          onChange={handleSchoolcodeChange}
        >
          <option value="">Select School Code</option>
          {schoolcodes &&
            schoolcodes.map((schoolcode) => (
              <option key={schoolcode._id} value={schoolcode.code}>
                {schoolcode.code}
              </option>
            ))}
        </select>
      </div>

      <div className="form-group">
        <label>Class</label>
        <select
          className="form-control"
          value={selectedClass}
          onChange={handleClassChange}
          disabled={!selectedSchoolcode}
        >
          <option value="">Select Class</option>
          {[
            ...new Set(
              users
                .filter((user) => user.schoolcode === selectedSchoolcode)
                .map((user) => user.classs)
            ),
          ].map((classs, index) => (
            <option key={index} value={classs}>
              {classs}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Section</label>
        <select
          className="form-control"
          value={selectedSection}
          onChange={handleSectionChange}
          disabled={!selectedClass}
        >
          <option value="">Select Section</option>
          {[
            ...new Set(
              users
                .filter(
                  (user) =>
                    user.schoolcode === selectedSchoolcode &&
                    user.classs === selectedClass
                )
                .map((user) => user.section)
            ),
          ].map((section, index) => (
            <option key={index} value={section}>
              {section}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Name</label>
        <select
          className="form-control"
          value={selectedName}
          onChange={handleNameChange}
          disabled={!selectedSection}
        >
          <option value="">Select Name</option>
          {filteredUsers.map((user) => (
            <option key={user._id} value={user.name}>
              {user.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Email</label>
        <select
          className="form-control"
          value={selectedEmail}
          onChange={handleEmailChange}
          disabled={!selectedName}
        >
          <option value="">Select Email</option>
          {filteredUsers
            .filter((user) => user.name === selectedName)
            .map((user) => (
              <option key={user._id} value={user.email}>
                {user.email}
              </option>
            ))}
        </select>
      </div>

      <hr />

      <div className="form-group">
        <label>Quiz Class</label>
        <select
          className="form-control"
          value={selectedQuizClass}
          onChange={handleQuizClassChange}
        >
          <option value="">Select Class</option>
          {[...new Set(quizs.map((quiz) => quiz.classs))].map(
            (classs, index) => (
              <option key={index} value={classs}>
                {classs}
              </option>
            )
          )}
        </select>
      </div>

      <div className="form-group">
        <label>Category</label>
        <select
          className="form-control"
          value={selectedCategory}
          onChange={handleCategoryChange}
          disabled={!selectedQuizClass}
        >
          <option value="">Select Category</option>
          {[
            ...new Set(
              quizs
                .filter((quiz) => quiz.classs === selectedQuizClass)
                .map((quiz) => quiz.difficulty)
            ),
          ].map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Subject</label>
        <select
          className="form-control"
          value={selectedSubject}
          onChange={handleSubjectChange}
          disabled={!selectedCategory}
        >
          <option value="">Select Subject</option>
          {[
            ...new Set(
              quizs
                .filter(
                  (quiz) =>
                    quiz.classs === selectedQuizClass &&
                    quiz.difficulty === selectedCategory
                )
                .map((quiz) => quiz.subject)
            ),
          ].map((subject, index) => (
            <option key={index} value={subject}>
              {subject}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Quiz Name</label>
        <select
          className="form-control"
          value={selectedQuiz}
          onChange={handleQuizChange}
          disabled={!selectedSubject}
        >
          <option value="">Select Quiz</option>
          {filteredQuizNames.map((quiz) => (
            <option key={quiz._id} value={quiz._id}>
              {quiz.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <button
          className="btn btn-primary"
          onClick={handleEnable}
          disabled={!selectedEmail || !selectedQuiz || isEmailEnabled()}
        >
          Enable
        </button>
        <button
          className="btn btn-danger ml-2"
          onClick={handleDisable}
          disabled={!selectedEmail || !selectedQuiz || !isEmailEnabled()}
        >
          Disable
        </button>
      </div>
    </div>
  );
};

export default EnableUser;
