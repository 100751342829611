import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Error from "../components/Error";
import { deleteStudent, getAllStudents } from "../actions/studentActions";
import { Link } from "react-router-dom";

export default function StudentsList() {
  const dispatch = useDispatch();
  const studentsstate = useSelector((state) => state.getAllStudentsReducer);
  const { students, error } = studentsstate;
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;

  useEffect(() => {
    dispatch(getAllStudents());
  }, [dispatch]);

  return (
    <div>
      <hr></hr>
      <h1>Backend Students List</h1>
      <hr></hr>
      <div className="table-responsive">
        {error && <Error error="Something went wrong" />}
        <table
          className="table table-striped table-bordered"
          style={{ borderColor: "#343a40" }}
        >
          <thead className="table-dark">
            <tr>
              <th className="p-3">First Name</th>
              <th className="p-3">Middle Name</th>
              <th className="p-3">Last Name</th>
              <th className="p-3">Name</th>
              <th className="p-3">Mobile</th>
              <th className="p-3">Email</th>
              <th className="p-3">School</th>
              <th className="p-3">Class</th>
              <th className="p-3">Section</th>
              <th className="p-3">Roll Number</th>
              <th className="p-3">City</th>
              <th className="p-3">Locality</th>
              <th className="p-3">Guardian</th>
              <th className="p-3">Key</th>
              <th className="p-3">School Code</th>
              {currentUser.isAdmin ? <th className="p-3">Actions</th> : ""}
            </tr>
          </thead>
          <tbody>
            {students &&
              students.map((student) => {
                return (
                  <tr key={student._id}>
                    <td className="p-3">{student.firstname}</td>
                    <td className="p-3">{student.middlename}</td>
                    <td className="p-3">{student.lastname}</td>
                    <td className="p-3">{student.name}</td>
                    <td className="p-3">{student.mobile}</td>
                    <td className="p-3">{student.email}</td>
                    <td className="p-3">{student.school}</td>
                    <td className="p-3">{student.classs}</td>
                    <td className="p-3">{student.section}</td>
                    <td className="p-3">{student.rollnumber}</td>
                    <td className="p-3">{student.city}</td>
                    <td className="p-3">{student.locality}</td>
                    <td className="p-3">{student.guardian}</td>
                    <td className="p-3">
                      {student.firstname + student.classs + student.rollnumber}
                    </td>
                    <td className="p-3">{student.schoolcode}</td>
                    {currentUser.isAdmin ? (
                      <td className="p-3">
                        <i
                          className="fa fa-trash m1"
                          onClick={() => {
                            dispatch(deleteStudent(student._id));
                          }}
                          style={{ cursor: "pointer" }}
                        ></i>
                        {/* <Link to={`/admin/editstudent/${student._id}`}>
                         <i className="fa fa-edit m1"></i>
                       </Link> */}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
