import React, { useState, useEffect, useRef } from "react";

const RestoreImage = () => {
  const [db, setDb] = useState(null); // State to hold the IndexedDB instance
  const [imageUrl, setImageUrl] = useState(""); // State to store the image URL for preview
  const canvasRef = useRef(null); // Reference for the canvas element
  const [imageFile, setImageFile] = useState(null); // State to hold the selected image file

  useEffect(() => {
    // Open IndexedDB on component mount
    const dbRequest = indexedDB.open("imageDB", 1);

    dbRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "id" });
      }
    };

    dbRequest.onsuccess = function (event) {
      setDb(event.target.result); // Store the database instance in state
    };

    dbRequest.onerror = function (event) {
      console.error("Error opening IndexedDB:", event.target.error);
    };
  }, []);

  // Load the selected image into the canvas
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file); // Save the file to state
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawing
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw image on canvas
        };
        img.src = e.target.result;
        setImageUrl(e.target.result); // Set the image URL for preview
      };
      reader.readAsDataURL(file);
    }
  };

  // Save the image from canvas to IndexedDB
  const saveToIndexedDB = () => {
    if (!db) {
      console.error("Database is not initialized.");
      return;
    }

    const canvas = canvasRef.current;
    const imageDataUrl = canvas.toDataURL(); // Get canvas data as Base64

    const transaction = db.transaction("images", "readwrite");
    const objectStore = transaction.objectStore("images");

    const imageRecord = {
      id: "savedImage", // Use a fixed ID for the image
      imageData: imageDataUrl,
    };

    objectStore.put(imageRecord);

    transaction.oncomplete = () => {
      alert("Image saved to IndexedDB!");
    };

    transaction.onerror = (event) => {
      console.error("Error saving image to IndexedDB:", event.target.error);
    };
  };

  // Restore the image from IndexedDB
  const restoreFromIndexedDB = () => {
    if (!db) {
      console.error("Database is not initialized.");
      return;
    }

    const transaction = db.transaction("images", "readonly");
    const objectStore = transaction.objectStore("images");

    const getRequest = objectStore.get("savedImage");

    getRequest.onsuccess = function (event) {
      const result = event.target.result;
      if (result) {
        const img = new Image();
        img.onload = function () {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawing
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw image on canvas
        };
        img.src = result.imageData; // Restore the image from data URL
      } else {
        alert("No image found in IndexedDB!");
      }
    };

    getRequest.onerror = (event) => {
      console.error(
        "Error retrieving image from IndexedDB:",
        event.target.error
      );
    };
  };

  return (
    <div>
      <h1>Image Storage using IndexedDB</h1>

      {/* Input to select an image */}
      <input type="file" accept="image/*" onChange={handleImageChange} />

      {/* Canvas to display the image */}
      <canvas
        ref={canvasRef}
        width="500"
        height="500"
        style={{ border: "1px solid black" }}
      ></canvas>

      <br />

      {/* Buttons to save and restore the image */}
      <button onClick={saveToIndexedDB}>Save</button>
      <button onClick={restoreFromIndexedDB}>Restore</button>

      {/* Optional: Image Preview (not on canvas) */}
      {imageUrl && (
        <div>
          <h2>Preview:</h2>
          <img src={imageUrl} alt="Selected" width="200" />
        </div>
      )}
    </div>
  );
};

export default RestoreImage;
