// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Error from "../components/Error";
// import {
//   deleteQuiz,
//   getAllQuizs,
//   updateQuizEnabledStatus,
// } from "../actions/quizActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions"; // Import the action
// import { Link } from "react-router-dom";

// export default function QuizsList() {
//   const dispatch = useDispatch();
//   const quizsstate = useSelector((state) => state.getAllQuizsReducer);
//   const schoolcodesstate = useSelector(
//     (state) => state.getAllSchoolcodesReducer
//   );
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userstate;
//   const { quizs, error } = quizsstate;
//   const { schoolcodes } = schoolcodesstate;

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllSchoolcodes()); // Fetch all schoolcodes
//   }, [dispatch]);

//   const handleEnabledChange = (quizId, schoolcode, enabled) => {
//     dispatch(updateQuizEnabledStatus(quizId, schoolcode, enabled));
//   };

//   return (
//     <div>
//       <hr />
//       <h1>Quizzes List</h1>
//       <hr />
//       <div className="table-responsive">
//         {error && <Error error="Something went wrong" />}
//         <table
//           className="table table-bordered"
//           style={{ borderColor: "#343a40" }}
//         >
//           <thead className="table-dark">
//             <tr>
//               <th>Name</th>
//               <th>Subject</th>
//               <th>Class</th>
//               <th>Category</th>
//               <th>Division</th>
//               <th>Enabled</th>
//               {currentUser.isAdmin ? <th>Actions</th> : ""}
//             </tr>
//           </thead>
//           <tbody>
//             {quizs &&
//               quizs.reverse().map((quiz) => {
//                 return (
//                   <tr key={quiz._id}>
//                     <td>{quiz.name}</td>
//                     <td>{quiz.subject}</td>
//                     <td>{quiz.classs}</td>
//                     <td>{quiz.difficulty}</td>
//                     <td>{quiz.division}</td>
//                     <td>
//                       {schoolcodes &&
//                         schoolcodes.map((schoolcode) => (
//                           <div key={schoolcode._id}>
//                             <label>
//                               <input
//                                 type="checkbox"
//                                 checked={quiz.enabled.includes(schoolcode.code)}
//                                 onChange={(e) =>
//                                   handleEnabledChange(
//                                     quiz._id,
//                                     schoolcode.code,
//                                     e.target.checked
//                                   )
//                                 }
//                               />
//                               {schoolcode.school}
//                             </label>
//                           </div>
//                         ))}
//                     </td>
//                     {currentUser.isAdmin ? (
//                       <td>
//                         <i
//                           className="fa fa-trash m1"
//                           style={{ cursor: "pointer", marginRight: "10px" }}
//                           onClick={() => {
//                             dispatch(deleteQuiz(quiz._id));
//                           }}
//                         ></i>
//                         <Link to={`/adminpanel/editquiz/${quiz._id}`}>
//                           <i
//                             className="fa fa-edit m1"
//                             style={{ cursor: "pointer" }}
//                           ></i>
//                         </Link>
//                       </td>
//                     ) : (
//                       ""
//                     )}
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }
///////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { getAllSchoolcodes } from "../actions/schoolcodeActions"; // Import the action
import { deleteQuiz, updateQuizEnabledStatus } from "../actions/quizActions";
import { Link } from "react-router-dom";

export default function QuizsList() {
  const dispatch = useDispatch();
  const [quizzes, setQuizzes] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const schoolcodesstate = useSelector(
    (state) => state.getAllSchoolcodesReducer
  );
  const { schoolcodes } = schoolcodesstate;

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;

  const observer = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    dispatch(getAllSchoolcodes()); // Fetch school codes on load
    fetchQuizzes(); // Fetch initial quizzes
  }, [dispatch]);

  useEffect(() => {
    if (page > 1) {
      fetchQuizzes();
    }
  }, [page]);

  const fetchQuizzes = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/quizs/getquizsbypage`, {
        params: { page, limit: 10 }, // Fetch 10 quizzes at a time
      });

      const newQuizzes = response.data;
      if (newQuizzes.length === 0) {
        setHasMore(false);
      }

      setQuizzes((prevQuizzes) => [...prevQuizzes, ...newQuizzes]);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch quizzes.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading || !hasMore) return;

    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const options = {
      root: null,
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver(observerCallback, options);

    if (triggerRef.current) {
      observer.current.observe(triggerRef.current);
    }

    return () => {
      if (observer.current && triggerRef.current) {
        observer.current.unobserve(triggerRef.current);
      }
    };
  }, [loading, hasMore]);

  const handleEnabledChange = (quizId, schoolcode, enabled) => {
    // Dispatch the action to update the state in the backend
    dispatch(updateQuizEnabledStatus(quizId, schoolcode, enabled));

    // Immediately update the local quizzes state to reflect the change in the enabled array
    setQuizzes((prevQuizzes) =>
      prevQuizzes.map((quiz) =>
        quiz._id === quizId
          ? {
              ...quiz,
              enabled: enabled
                ? [...quiz.enabled, schoolcode]
                : quiz.enabled.filter((code) => code !== schoolcode),
            }
          : quiz
      )
    );
  };

  return (
    <div style={{ overflow: "visible", height: "auto" }}>
      <hr />
      <h1>Quizzes List</h1>
      <hr />
      <div className="table-responsive" style={{ overflow: "visible" }}>
        {error && <Error error="Something went wrong" />}
        {loading && <Loading />}
        <table
          className="table table-bordered"
          style={{ borderColor: "#343a40", overflow: "visible" }}
        >
          <thead className="table-dark">
            <tr>
              <th>Name</th>
              <th>Subject</th>
              <th>Class</th>
              <th>Category</th>
              <th>Division</th>
              <th>Enabled</th>
              {currentUser.isAdmin || currentUser.isSubAdminTwo ? (
                <th>Actions</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {quizzes.map((quiz) => (
              <tr key={quiz._id}>
                <td>{quiz.name}</td>
                <td>{quiz.subject}</td>
                <td>{quiz.classs}</td>
                <td>{quiz.difficulty}</td>
                <td>{quiz.division}</td>
                <td>
                  {schoolcodes &&
                    schoolcodes.map((schoolcode) => (
                      <div key={schoolcode._id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={quiz.enabled.includes(schoolcode.code)}
                            onChange={(e) =>
                              handleEnabledChange(
                                quiz._id,
                                schoolcode.code,
                                e.target.checked
                              )
                            }
                          />
                          {schoolcode.school}
                        </label>
                      </div>
                    ))}
                </td>

                <td>
                  {currentUser.isAdmin ? (
                    <i
                      className="fa fa-trash m1"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={() => dispatch(deleteQuiz(quiz._id))}
                    ></i>
                  ) : null}
                  {currentUser.isAdmin || currentUser.isSubAdminTwo ? (
                    <Link to={`/adminpanel/editquiz/${quiz._id}`}>
                      <i
                        className="fa fa-edit m1"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </Link>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Trigger div at the bottom of the page */}
        <div
          ref={triggerRef}
          style={{
            height: "20px",
            backgroundColor: "lightgray", // Make it visible
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {loading && hasMore
            ? "Loading more quizzes..."
            : "No more quizzes to display"}
        </div>
      </div>
    </div>
  );
}
