import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import RainbowText from "react-rainbow-text";
import "./TeacherTraining.css";

const offerings = [
  {
    title: "Innovative Teaching Methods",
    description:
      "Learn the latest pedagogical approaches to enhance student engagement and learning outcomes.",
  },
  {
    title: "Technology Integration",
    description:
      "Master the use of educational technology to create interactive and dynamic learning environments.",
  },
  {
    title: "Classroom Management",
    description:
      "Develop effective strategies to maintain a positive and productive classroom atmosphere.",
  },
  {
    title: "Personalized Learning",
    description:
      "Tailored training sessions to meet the unique needs of individual educators and institutions.",
  },
];

const images = [
  "wa12.png",
  "wa22.png",
  "wa32.png",
  "wa4.jpeg",
  "wa5.jpeg",
  "wa6.jpeg",
];

const TeacherTraining = () => {
  const headingRef = useRef(null);
  const cardsRef = useRef([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            if (entry.target === headingRef.current) {
              setShowConfetti(true);
            }
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{ padding: "40px 0", background: "white", position: "relative" }}
    >
      {/* {showConfetti && (
        <Confetti width={window.screen.width} height={2100} gravity={0.02} />
      )} */}
      <h2
        ref={headingRef}
        style={{
          textAlign: "center",
          fontFamily: '"Libre Baskerville", serif',
          fontSize: isMobile ? "2em" : "3em",
          fontWeight: "bold",
          marginTop: "5vh",
          marginBottom: "2vh",
          marginLeft: "10px",
          marginRight: "10px",
          width: "100%",
        }}
      >
        {/* <RainbowText lightness={0.5} saturation={1}>
          Teacher Training Program at Gyankosha
        </RainbowText> */}
        Teacher Training Program at Gyankosha
      </h2>
      <p
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontSize: "20px",
          color: "black",
          marginLeft: "5vh",
          marginRight: "5vh",
        }}
      >
        At Gyankosha, we are dedicated to empowering educators with the skills
        and knowledge they need to excel in the modern classroom. Our Teacher
        Training Program is designed to provide comprehensive professional
        development, focusing on innovative teaching methodologies, technology
        integration, and effective classroom management strategies.
      </p>
      <div
        className="image-box"
        style={{
          marginTop: "40px",
          overflow: "hidden",
          width: "100%",
          height: isMobile ? "200px" : "915px",
        }}
      >
        <div
          className="image-slider"
          style={{
            display: "flex",
            transition: "transform 1s ease",
            transform: `translateX(-${currentImageIndex * 100}%)`,
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              style={{ minWidth: "100%", height: isMobile ? "200px" : "915px" }}
            />
          ))}
        </div>
      </div>
      <div
        className="box-container"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
          flexWrap: "wrap",
          padding: "20px",
          marginTop: isMobile ? "5px" : "20px",
          //background: "white",
          background: "white",
        }}
      >
        {offerings.map((offering, index) => (
          <div
            key={index}
            ref={(el) => (cardsRef.current[index] = el)}
            className="box"
          >
            <div
              className="box-title"
              style={{
                textAlign: "center",
                fontFamily: '"Libre Baskerville", serif',
                fontSize: "2em",
                fontWeight: "bold",
                marginTop: isMobile ? "2vh" : "5vh",
                marginBottom: "2vh",
                width: "100%",
              }}
            >
              {/* <RainbowText lightness={0.3} saturation={1}>
                {offering.title}
              </RainbowText> */}
              {offering.title}
            </div>
            <br></br>
            <div
              className="box-description"
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontSize: "20px",
                //fontWeight: "bold",
              }}
            >
              {offering.description}
            </div>
          </div>
        ))}
      </div>

      <div style={{ color: "black", background: "whiite" }}>
        <p
          style={{
            fontFamily: '"Libre Baskerville", serif',
            fontSize: "20px",
            color: "black",
            marginTop: "5vh",
            marginLeft: "5vh",
            marginRight: "5vh",
          }}
        >
          Join Gyankosha's Teacher Training Program and become a part of a
          community committed to educational excellence and continuous
          improvement. Transform your teaching practices and inspire the next
          generation of learners with Gyankosha.
        </p>
        {/* <p
          style={{
            fontFamily: '"Libre Baskerville", serif',
            fontSize: "30px",
            color: "black",
          }}
        >
          For any queries, please contact :{" "}
          <RainbowText lightness={0.5} saturation={1}>
            9643009106 / ashutosh@gyankosha.com
          </RainbowText>
          9643009106 / ashutosh@gyankosha.com
        </p> */}
      </div>
    </div>
  );
};

export default TeacherTraining;
