import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { deleteQuiz, updateQuizEnabledStatus } from "../actions/quizActions";
import { Link } from "react-router-dom";

export default function QuizSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const schoolcodesstate = useSelector(
    (state) => state.getAllSchoolcodesReducer
  );
  const { schoolcodes } = schoolcodesstate;

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;

  useEffect(() => {
    dispatch(getAllSchoolcodes()); // Fetch school codes when the page loads
  }, [dispatch]);

  const handleSearch = async () => {
    if (!searchTerm) return; // Prevent empty search

    try {
      setLoading(true);
      const response = await axios.get("/api/quizs/search", {
        params: { name: searchTerm },
      });

      setQuizzes(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch quizzes.");
      setLoading(false);
    }
  };

  const handleEnabledChange = (quizId, schoolcode, enabled) => {
    // Dispatch the action to update the state in the backend
    dispatch(updateQuizEnabledStatus(quizId, schoolcode, enabled));

    // Immediately update the local quizzes state to reflect the change in the enabled array
    setQuizzes((prevQuizzes) =>
      prevQuizzes.map((quiz) =>
        quiz._id === quizId
          ? {
              ...quiz,
              enabled: enabled
                ? [...quiz.enabled, schoolcode]
                : quiz.enabled.filter((code) => code !== schoolcode),
            }
          : quiz
      )
    );
  };

  return (
    <div>
      <h1>Search Quizzes</h1>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by quiz name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {error && <Error error={error} />}
      {loading && <Loading />}

      {quizzes.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th>Subject</th>
                <th>Class</th>
                <th>Category</th>
                <th>Division</th>
                <th>Enabled</th>
                {currentUser.isAdmin || currentUser.isSubAdminTwo ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {quizzes.map((quiz) => (
                <tr key={quiz._id}>
                  <td>{quiz.name}</td>
                  <td>{quiz.subject}</td>
                  <td>{quiz.classs}</td>
                  <td>{quiz.difficulty}</td>
                  <td>{quiz.division}</td>
                  <td>
                    {schoolcodes &&
                      schoolcodes.map((schoolcode) => (
                        <div key={schoolcode._id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={quiz.enabled.includes(schoolcode.code)}
                              onChange={(e) =>
                                handleEnabledChange(
                                  quiz._id,
                                  schoolcode.code,
                                  e.target.checked
                                )
                              }
                            />
                            {schoolcode.school}
                          </label>
                        </div>
                      ))}
                  </td>

                  <td>
                    {currentUser.isAdmin ? (
                      <i
                        className="fa fa-trash m1"
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => dispatch(deleteQuiz(quiz._id))}
                      ></i>
                    ) : null}
                    {currentUser.isAdmin || currentUser.isSubAdminTwo ? (
                      <Link to={`/adminpanel/editquiz/${quiz._id}`}>
                        <i
                          className="fa fa-edit m1"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Link>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {quizzes.length === 0 && !loading && <p>No quizzes found.</p>}
    </div>
  );
}
