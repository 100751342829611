import React from "react";
import "./FooterInfo.css"; // Assuming you will create a separate CSS file for styles

function FooterInfo() {
  return (
    <div className="footer-info">
      <div
        className="about-us"
        style={{
          fontFamily: '"Libre Baskerville", serif'
        }}
      >
        <h2 style={{fontWeight:"bold"}}>Contact us</h2>
        <p style={{fontSize:"25px"}}>
          email : ashutosh@gyankosha.com
        </p>
      </div>
      <div
        className="social-media"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        <h2 style={{fontWeight:"bold"}}>Social Media</h2>
        <div className="icons">
          <a
            href="https://www.youtube.com/@gyankosha"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/gyankosha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a
            href="https://www.instagram.com/_gyankosha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default FooterInfo;
