import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { deleteUser, updatePaidStatus } from "../actions/userActions";

export default function UserSearch() {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [users, setUsers] = useState([]); // State for storing filtered users
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate; // To check if the user is an admin

  const handleSearch = async () => {
    if (!searchTerm) return; // Prevent empty search

    try {
      setLoading(true);
      setError("");

      // Make an API call to search users by email
      const response = await axios.get("/api/users/search", {
        params: { email: searchTerm },
      });

      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch users.");
      setLoading(false);
    }
  };

  const handlePaidChange = async (userId, paid) => {
    try {
      // Optimistically update the UI immediately after checkbox click
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, paid: paid } : user
        )
      );

      // Make the API call to update the paid status in the backend
      await axios.post("/api/users/updatepaidstatus", { userId, paid });

      // Optionally, you can dispatch the Redux action here if needed:
      dispatch(updatePaidStatus(userId, paid));
    } catch (error) {
      setError("Error updating paid status.");
    }
  };

  const handleDeleteUser = (userId) => {
    // Call the Redux action to delete a user
    dispatch(deleteUser(userId));

    // Optimistically update the UI by removing the user from the list
    setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
  };

  return (
    <div>
      <h1>Search Users by Email</h1>

      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {loading && <Loading />}
      {error && <Error error={error} />}

      {users.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>UserId</th>
                <th>Name</th>
                <th>Email</th>
                <th>School</th>
                <th>School Code</th>
                <th>Class</th>
                <th>Section</th>
                <th>Mobile No.</th>
                <th>City</th>
                <th>Paid</th>
                {currentUser.isAdmin && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.school}</td>
                  <td>{user.schoolcode}</td>
                  <td>{user.classs}</td>
                  <td>{user.section}</td>
                  <td>{user.mobile}</td>
                  <td>{user.city}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.paid}
                      onChange={(e) =>
                        handlePaidChange(user._id, e.target.checked)
                      }
                    />
                  </td>
                  {currentUser.isAdmin && (
                    <td>
                      <i
                        className="fa fa-trash"
                        onClick={() => handleDeleteUser(user._id)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      ></i>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {users.length === 0 && !loading && <p>No users found.</p>}
    </div>
  );
}
