import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import "./Quiz.css";

function Quiz({
  quiz,
  userId,
  userName,
  userSchoolcode,
  userClass,
  userPaid,
  userSection,
  attemptedQuizIds,
  completedQuizIds,
  updateDifficulty, // Add this prop to update difficulty in Udbodhscreen
}) {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => setshowModal(true);

  const modifyImageUrl = (imageUrl) => {
    const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
    return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  };

  // Define a common style object for button shadows
  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  const handleStartOrResumeQuiz = async () => {
    try {
      if (
        !attemptedQuizIds.includes(quiz._id) &&
        !completedQuizIds.includes(quiz._id)
      ) {
        await axios.post("/api/users/attemptquiz", {
          userId,
          quizId: quiz._id,
          totalQuestions: quiz.questions.length,
          correctAnswers: "",
          wrongAnswers: "",
          attemptedQuestions: "",
          division: quiz.division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        });
      }
      navigate("/questions", {
        state: {
          userName: userName,
          userSchoolcode: userSchoolcode,
          userClass: userClass,
          userSection: userSection,
          name: quiz.name,
          questions: quiz.questions,
          division: quiz.division,
          userId: userId, // Pass userId to QuestionPage
          quizId: quiz._id, // Pass quizId to QuestionPage
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        },
      });
    } catch (error) {
      console.error("Error at Start/Resume Quiz", error);
    }
  };

  const handleFreeExploratoryQuizzes = () => {
    updateDifficulty("Free Exploratory Quiz"); // Change difficulty to Free Exploratory Quiz
    setshowModal(false); // Close modal after setting difficulty
  };

  const handlePlansforPremiumQuizzes = () => {
    navigate("/paymentplans"); // Navigate to the premium plans page
  };

  const getButtonLabel = () => {
    if (completedQuizIds.includes(quiz._id)) {
      return "Retake Quiz";
    }
    if (attemptedQuizIds.includes(quiz._id)) {
      return "Resume Quiz";
    }
    return "Start Quiz";
  };
  // const getButtonLabel = () => {
  //   console.log("quiz._id : ", quiz._id);
  //   console.log("completedQuizIds : ", completedQuizIds);
  //   console.log("attemptedQuizIds : ", attemptedQuizIds);
  //   // Convert quiz._id to string for comparison
  //   const quizIdStr = quiz._id.toString();

  //   // Check if the quiz is in completedQuizIds by comparing strings
  //   if (completedQuizIds.some((id) => id.toString() === quizIdStr)) {
  //     console.log("Matched Completed Quiz ID:", quizIdStr);
  //     return "Retake Quiz";
  //   }

  //   // Check if the quiz is in attemptedQuizIds by comparing strings
  //   if (attemptedQuizIds.some((id) => id.toString() === quizIdStr)) {
  //     console.log("Matched Attempted Quiz ID:", quizIdStr);
  //     return "Resume Quiz";
  //   }

  //   return "Start Quiz";
  // };

  return (
    <div
    className="shadow-lg p-3 mb-5 bg-white"
    id="round"
    onClick={
      userPaid || quiz.difficulty === "Free Exploratory Quiz"
        ? handleStartOrResumeQuiz
        : handleShowModal
    }
    style={{ cursor: "pointer" }} // Adds pointer cursor on hover
  >
    {quiz.quiz_image && (
      <img
        src={modifyImageUrl(quiz.quiz_image)}
        alt="Quiz"
        width="200px"
        height="200px"
        className="img-fluid"
      />
    )}
    <br />
    <h1
      style={{
        fontFamily: "'Georgia Ref', Georgia, serif",
        fontWeight: "bold",
        fontSize: "20px",
        textAlign: "center",
      }}
    >
      {quiz.name}
    </h1>
    <br />
    <button
      className="btn btn-primary"
      style={{
        ...buttonShadowStyle,
        fontFamily: "'Georgia Ref', Georgia, serif",
        fontSize: "25px",
        textAlign: "center",
      }}
      onClick={(e) => {
        e.stopPropagation(); // Prevents the card's onClick from firing
        userPaid || quiz.difficulty === "Free Exploratory Quiz"
          ? handleStartOrResumeQuiz()
          : handleShowModal();
      }}
    >
      {getButtonLabel()}
    </button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg" // Optional: 'sm', 'lg', 'xl'
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textAlign: "center",
              fontFamily: "Baskerville, serif",
              fontWeight: "bold",
            }}
          >
            This is a Premium Quiz.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontFamily: "Baskerville, serif",
            fontSize: "20px",
          }}
        >
          Please Click on "Free Exploratory Quizzes" to explore the Free
          Quizzess.
          <br></br>
          <br></br>
          OR
          <br></br>
          <br></br>
          Explore "Plans for Premium Quizzes"
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center", // Centers the buttons horizontally
          }}
        >
          <button
            className="btn"
            onClick={handleFreeExploratoryQuizzes}
            style={{ fontFamily: "Baskerville, serif", fontSize: "20px" }}
          >
            Free Exploratory Quizzes
          </button>
          <button
            className="btn"
            onClick={handlePlansforPremiumQuizzes}
            style={{ fontFamily: "Baskerville, serif", fontSize: "20px" }}
          >
            Plans for Premium Quizzes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Quiz;
/////////////////////////////////////////////////////////////////////
